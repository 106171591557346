import "./index.css"
import React, { useState } from 'react';

import {AiOutlineForm} from "react-icons/ai"
import { useNavigate } from 'react-router-dom';

import {BiSolidUser} from 'react-icons/bi'
import {MdEmail} from 'react-icons/md'
import {MdLocationPin} from 'react-icons/md'
import {BiSolidPhoneCall} from 'react-icons/bi'
import {FaCommentDots} from 'react-icons/fa'
import {BiSolidCategory} from 'react-icons/bi'
import {PiCertificateFill} from 'react-icons/pi'
import  Button  from "../../Button";

const SelectCourseList=[
  {id:0,item:"Bachelors"},{id:1,item:"MBA/PGDM-Masters"},{id:2,item:"MS/M.Sc-Masters"},{id:3,item:"Masters"},{id:4,item:"Doctorate/Ph.D"},{id:5,item:"Certificate"},{id:6,item:"Grad Certificate"},{id:7,item:"Diploma"},{id:8,item:"PG Diploma"}]


const SelectCourseList1={
  "Bachelors":{id:0,item:"Bachelors",detail:[
    { id: 0, course: "Sciences" },
    { id: 1, course: "Arts" },
    { id: 2, course: "Engineering" },
    { id: 3, course: "Management" },
    { id: 4, course: "Business" },
    { id: 5, course: "Humanities" },
    { id: 6, course: "Social Studies" },
    { id: 7, course: "Computer Science" },
    { id: 8, course: "Mechanical Engineering" },
    { id: 9, course: "Finance" },
    { id: 10, course: "Electrical and Electronics Engineering" },
    { id: 11, course: "Civil Engineering" },
    { id: 12, course: "Information Technology" },
    { id: 13, course: "Data Science and Analytics" },
    { id: 14, course: "Supply Chain Management" },
    { id: 15, course: "Hotel Management" },
    { id: 16, course: "Tourism and Hospitality" },
    { id: 17, course: "Business Analytics" },
    { id: 18, course: "Medicine" },
    { id: 19, course: "Education" },
    { id: 20, course: "Language" },
    { id: 21, course: "Journalism" },
    { id: 22, course: "Environmental Studies" },
    { id: 23, course: "Economics" },
    { id: 24, course: "Law" },
    { id: 25, course: "Political Science" },
    { id: 26, course: "Marketing" },
    { id: 27, course: "Nursing" },
    { id: 28, course: "Pharmacy" },
    { id: 29, course: "Psychology" },
    { id: 30, course: "Other Courses" },
    { id: 31, course: "Natural Sciences" },
    { id: 32, course: "Biology" },
    { id: 33, course: "Music" },
    { id: 34, course: "History" },
    { id: 35, course: "Mathematics" },
    { id: 36, course: "Performing Arts" },
    { id: 37, course: "Physics" },
    { id: 38, course: "Design" },
    { id: 39, course: "Chemistry" },
    { id: 40, course: "International Business" },
    { id: 41, course: "Accounting" },
    { id: 42, course: "Technology" },
    { id: 43, course: "Geography" },
    { id: 44, course: "Fine Arts" },
    { id: 45, course: "Biotechnology" },
    { id: 46, course: "Architecture" },
    { id: 47, course: "Philosophy" },
    { id: 48, course: "Media Studies" },
    { id: 49, course: "Sports Sciences" },
    { id: 50, course: "Biochemistry" },
    { id: 51, course: "Information Studies" },
    { id: 52, course: "Health Care" },
    { id: 53, course: "Agriculture" },
    { id: 54, course: "Film, Photography and Media" },
    { id: 55, course: "Literature" },
    { id: 56, course: "Graphic Design" },
    { id: 57, course: "English" },
    { id: 58, course: "Spanish" },
    { id: 59, course: "Anthropology" },
    { id: 60, course: "Communication Studies" },
    { id: 61, course: "Food Science" },
    { id: 62, course: "Biomedical Studies" },
    { id: 63, course: "Statistics" },
    { id: 64, course: "Writing" },
    { id: 65, course: "French" },
    { id: 66, course: "Entrepreneurship" },
    { id: 67, course: "Chemical Engineering" },
    { id: 68, course: "Human Resource Management" },
    { id: 69, course: "Fashion Design" },
    { id: 70, course: "Public Relations" },
    { id: 71, course: "Industrial Engineering" },
    { id: 72, course: "Kinesiology" },
    { id: 73, course: "Leadership" },
    { id: 74, course: "Neuroscience" },
    { id: 75, course: "Commerce" },
    { id: 76, course: "Forensic Sciences" },
    { id: 77, course: "Ecology" },
    { id: 78, course: "Software Engineering" },
    { id: 79, course: "Interior Design" },
    { id: 80, course: "Earth Sciences" },
    { id: 81, course: "Human Science" },
    { id: 82, course: "Public Policy" },
    { id: 83, course: "Animation" },
    { id: 84, course: "Electronic and Communications Engineering" },
    { id: 85, course: "Visual Arts" },
    { id: 86, course: "Games Development" },
    { id: 87, course: "Molecular Biology" },
    { id: 88, course: "Advertising" },
    { id: 89, course: "Astronomy" },
    { id: 90, course: "Materials Sciences" },
    { id: 91, course: "Microbiology" },
    { id: 92, course: "Construction Management" },
    { id: 93, course: "Actuarial Science" },
    { id: 94, course: "Marine Sciences" },
    { id: 95, course: "Aerospace Engineering" },
    { id: 96, course: "Archaeology" },
    { id: 97, course: "Dentistry" },
    { id: 98, course: "Real Estate" },
    { id: 99, course: "Zoology" },
    { id: 100, course: "Development Studies" },
    { id: 101, course: "Theology" },
    { id: 102, course: "Business Economics" },
    { id: 103, course: "Horticulture" },
    { id: 104, course: "Event Management" },
    { id: 105, course: "Engineering Management" },
    { id: 106, course: "Aviation" },
    { id: 107, course: "Physiotherapy" },
    { id: 108, course: "Medical Technology" },
    { id: 109, course: "Cyber Security" },
    { id: 110, course: "Sustainable Studies" },
    { id: 111, course: "Genetics" },
    { id: 112, course: "Plant Sciences" },
    { id: 113, course: "Veterinary" },
    { id: 114, course: "Automotive Engineering" },
    { id: 115, course: "Forestry" },
    { id: 116, course: "Risk Management" },
    { id: 117, course: "Energy and Power Engineering" },
    { id: 118, course: "Network Engineering" },
    { id: 119, course: "Acting" },
    { id: 120, course: "Agribusiness" },
    { id: 121, course: "European Studies" },
    { id: 122, course: "Project Management" },
    { id: 123, course: "Robotics" },
    { id: 124, course: "Gender Studies" },
    { id: 125, course: "Construction Engineering" },
    { id: 126, course: "Disaster Management" },
    { id: 127, course: "Artificial Intelligence and Machine Learning" },
    { id: 128, course: "Animal Sciences" },
    { id: 129, course: "Atmospheric Science" },
    { id: 130, course: "Medical Laboratory Sciences" },
    { id: 131, course: "Fisheries" },
    { id: 132, course: "Aeronautical Engineering" },
    { id: 133, course: "Mining Engineering" },
    { id: 134, course: "Banking" },
    { id: 135, course: "Bioinformatics" },
    { id: 136, course: "Soil Science" },
    { id: 137, course: "Agricultural Engineering" },
    { id: 138, course: "Clinical Science" },
    { id: 139, course: "Immunology" },
    { id: 140, course: "Culinary Arts" },
    { id: 141, course: "Counselling" },
    { id: 142, course: "Retail Management" },
    { id: 143, course: "Nanotechnology" },
    { id: 144, course: "Nuclear Engineering" },
    { id: 145, course: "Operations Management" },
    { id: 146, course: "Instrumentation and Control Engineering" },
    { id: 147, course: "Toxicology" },
    { id: 148, course: "Urban Studies" },
    { id: 149, course: "Digital Marketing" },
    { id: 150, course: "Agricultural Economics" },
    { id: 151, course: "MIS" },
    { id: 152, course: "Aboriginal Studies" },
    { id: 153, course: "Library Sciences" },
    { id: 154, course: "Instructional Design" },
    { id: 155, course: "Chinese" },
    { id: 156, course: "Epidemiology" },
    { id: 157, course: "Others" }
  ]
  },
  "MBA/PGDM-Masters":{id:1,item:"MBA/PGDM-Masters",detail:[
    { id: 0, course: "Business" },
    { id: 1, course: "Finance" },
    { id: 2, course: "International Business" },
    { id: 3, course: "Accounting" },
    { id: 4, course: "Marketing" },
    { id: 5, course: "Medicine" },
    { id: 6, course: "Health Care" },
    { id: 7, course: "Engineering" },
    { id: 8, course: "Social Studies" },
    { id: 9, course: "Entrepreneurship" },
    { id: 10, course: "Business Analytics" },
    { id: 11, course: "Leadership" },
    { id: 12, course: "Supply Chain Management" },
    { id: 13, course: "Human Resource Management" },
    { id: 14, course: "Information Studies" },
    { id: 15, course: "Sciences" },
    { id: 16, course: "Law" },
    { id: 17, course: "Computer Science and Engineering" },
    { id: 18, course: "Technology" },
    { id: 19, course: "Arts" },
    { id: 20, course: "Project Management" },
    { id: 21, course: "Information Technology" },
    { id: 22, course: "Tourism and Hospitality" },
    { id: 23, course: "Sports Sciences" },
    { id: 24, course: "Economics" },
    { id: 25, course: "Real Estate" },
    { id: 26, course: "Architecture" },
    { id: 27, course: "Energy and Power Engineering" },
    { id: 28, course: "Hotel Management" },
    { id: 29, course: "Others" }
  ]
  },
  "MS/M.Sc-Masters":{id:2,item:"MS/M.Sc-Masters", detail:[
    { id: 0, course: "Sciences" },
    { id: 1, course: "Engineering" },
    { id: 2, course: "Business" },
    { id: 3, course: "Medicine" },
    { id: 4, course: "Biology" },
    { id: 5, course: "Computer Science and Engineering" },
    { id: 6, course: "Natural Sciences" },
    { id: 7, course: "Environmental Studies" },
    { id: 8, course: "Mathematics" },
    { id: 9, course: "Finance" },
    { id: 10, course: "Education" },
    { id: 11, course: "Technology" },
    { id: 12, course: "Nursing" },
    { id: 13, course: "Physics" },
    { id: 14, course: "Accounting" },
    { id: 15, course: "Health Care" },
    { id: 16, course: "Chemistry" },
    { id: 17, course: "Social Studies" },
    { id: 18, course: "Electrical and Electronics Engineering" },
    { id: 19, course: "Marketing" },
    { id: 20, course: "Mechanical Engineering" },
    { id: 21, course: "Biotechnology" },
    { id: 22, course: "Data Science and Analytics" },
    { id: 23, course: "Economics" },
    { id: 24, course: "Psychology" },
    { id: 25, course: "Information Studies" },
    { id: 26, course: "Statistics" },
    { id: 27, course: "Civil Engineering" },
    { id: 28, course: "Biomedical Studies" },
    { id: 29, course: "International Business" },
    { id: 30, course: "Architecture" },
    { id: 31, course: "Geography" },
    { id: 32, course: "Leadership" },
    { id: 33, course: "Pharmacy" },
    { id: 34, course: "Information Technology" },
    { id: 35, course: "Industrial Engineering" },
    { id: 36, course: "Others" }
  ]
  }, 
  "Masters":{id:3,item:"Masters",detail:[
    { id: 0, course: "Sciences" },
    { id: 1, course: "Arts" },
    { id: 2, course: "Engineering" },
    { id: 3, course: "Management" },
    { id: 4, course: "Business" },
    { id: 5, course: "Humanities" },
    { id: 6, course: "Social Work" },
    { id: 7, course: "Social Studies" },
    { id: 8, course: "Computer Science" },
    { id: 9, course: "Mechanical Engineering" },
    { id: 10, course: "Finance" },
    { id: 11, course: "Electrical and Electronics Engineering" },
    { id: 12, course: "Civil Engineering" },
    { id: 13, course: "Information Technology" },
    { id: 14, course: "Data Science and Analytics" },
    { id: 15, course: "MIS" },
    { id: 16, course: "Health Care" },
    { id: 17, course: "Supply Chain Management" },
    { id: 18, course: "Hotel Management" },
    { id: 19, course: "Tourism and Hospitality" },
    { id: 20, course: "Business Analytics" },
    { id: 21, course: "Medicine" },
    { id: 22, course: "Education" },
    { id: 23, course: "Language" },
    { id: 24, course: "Journalism" },
    { id: 25, course: "Environmental Studies" },
    { id: 26, course: "Economics" },
    { id: 27, course: "Law" },
    { id: 28, course: "Political Science" },
    { id: 29, course: "Marketing" },
    { id: 30, course: "Nursing" },
    { id: 31, course: "Pharmacy" },
    { id: 32, course: "Other Courses" },
    { id: 33, course: "Natural Sciences" },
    { id: 34, course: "Biology" },
    { id: 35, course: "Music" },
    { id: 36, course: "History" },
    { id: 37, course: "Mathematics" },
    { id: 38, course: "Performing Arts" },
    { id: 39, course: "Psychology" },
    { id: 40, course: "Physics" },
    { id: 41, course: "Design" },
    { id: 42, course: "Chemistry" },
    { id: 43, course: "International Business" },
    { id: 44, course: "Accounting" },
    { id: 45, course: "Technology" },
    { id: 46, course: "Geography" },
    { id: 47, course: "Fine Arts" },
    { id: 48, course: "Biotechnology" },
    { id: 49, course: "Architecture" },
    { id: 50, course: "Philosophy" },
    { id: 51, course: "Media Studies" },
    { id: 52, course: "Sports Sciences" },
    { id: 53, course: "Biochemistry" },
    { id: 54, course: "Information Studies" },
    { id: 55, course: "Agriculture" },
    { id: 56, course: "Film, Photography and Media" },
    { id: 57, course: "Literature" },
    { id: 58, course: "Graphic Design" },
    { id: 59, course: "English" },
    { id: 60, course: "Spanish" },
    { id: 61, course: "Anthropology" },
    { id: 62, course: "Communication Studies" },
    { id: 63, course: "Food Science" },
    { id: 64, course: "Biomedical Studies" },
    { id: 65, course: "Statistics" },
    { id: 66, course: "Writing" },
    { id: 67, course: "French" },
    { id: 68, course: "Entrepreneurship" },
    { id: 69, course: "Chemical Engineering" },
    { id: 70, course: "Human Resource Management" },
    { id: 71, course: "Fashion Design" },
    { id: 72, course: "Public Relations" },
    { id: 73, course: "Industrial Engineering" },
    { id: 74, course: "Kinesiology" },
    { id: 75, course: "Leadership" },
    { id: 76, course: "Neuroscience" },
    { id: 77, course: "Commerce" },
    { id: 78, course: "Forensic Sciences" },
    { id: 79, course: "Ecology" },
    { id: 80, course: "Software Engineering" },
    { id: 81, course: "Interior Design" },
    { id: 82, course: "Earth Sciences" },
    { id: 83, course: "Human Science" },
    { id: 84, course: "Public Policy" },
    { id: 85, course: "Animation" },
    { id: 86, course: "Electronic and Communications Engineering" },
    { id: 87, course: "Visual Arts" },
    { id: 88, course: "Games Development" },
    { id: 89, course: "Molecular Biology" },
    { id: 90, course: "Advertising" },
    { id: 91, course: "Astronomy" },
    { id: 92, course: "Materials Sciences" },
    { id: 93, course: "Microbiology" },
    { id: 94, course: "Construction Management" },
    { id: 95, course: "Actuarial Science" },
    { id: 96, course: "Marine Sciences" },
    { id: 97, course: "Aerospace Engineering" },
    { id: 98, course: "Archaeology" },
    { id: 99, course: "Dentistry" },
    { id: 100, course: "Real Estate" },
    { id: 101, course: "Zoology" },
    { id: 102, course: "Development Studies" },
    { id: 103, course: "Theology" },
    { id: 104, course: "Business Economics" },
    { id: 105, course: "Horticulture" },
    { id: 106, course: "Event Management" },
    { id: 107, course: "Engineering Management" },
    { id: 108, course: "Aviation" },
    { id: 109, course: "Physiotherapy" },
    { id: 110, course: "Medical Technology" },
    { id: 111, course: "Cyber Security" },
    { id: 112, course: "Sustainable Studies" },
    { id: 113, course: "Genetics" },
    { id: 114, course: "Plant Sciences" },
    { id: 115, course: "Veterinary" },
    { id: 116, course: "Automotive Engineering" },
    { id: 117, course: "Forestry" },
    { id: 118, course: "Risk Management" },
    { id: 119, course: "Energy and Power Engineering" },
    { id: 120, course: "Network Engineering" },
    { id: 121, course: "Acting" },
    { id: 122, course: "Agribusiness" },
    { id: 123, course: "European Studies" },
    { id: 124, course: "Project Management" },
    { id: 125, course: "Robotics" },
    { id: 126, course: "Gender Studies" },
    { id: 127, course: "Construction Engineering" },
    { id: 128, course: "Disaster Management" },
    { id: 129, course: "Artificial Intelligence and Machine Learning" },
    { id: 130, course: "Animal Sciences" },
    { id: 131, course: "Atmospheric Science" },
    { id: 132, course: "Medical Laboratory Sciences" },
    { id: 133, course: "Fisheries" },
    { id: 134, course: "Aeronautical Engineering" },
    { id: 135, course: "Mining Engineering" },
    { id: 136, course: "Banking" },
    { id: 137, course: "Bioinformatics" },
    { id: 138, course: "Soil Science" },
    { id: 139, course: "Agricultural Engineering" },
    { id: 140, course: "Clinical Science" },
    { id: 141, course: "Immunology" },
    { id: 142, course: "Culinary Arts" },
    { id: 143, course: "Counselling" },
    { id: 144, course: "Retail Management" },
    { id: 145, course: "Nanotechnology" },
    { id: 146, course: "Nuclear Engineering" },
    { id: 147, course: "Operations Management" },
    { id: 148, course: "Instrumentation and Control Engineering" },
    { id: 149, course: "Toxicology" },
    { id: 150, course: "Urban Studies" },
    { id: 151, course: "Digital Marketing" },
    { id: 152, course: "Agricultural Economics" },
    { id: 153, course: "Aboriginal Studies" },
    { id: 154, course: "Library Sciences" },
    { id: 155, course: "Instructional Design" },
    { id: 156, course: "Chinese" },
    { id: 157, course: "Epidemiology" },
    { id: 158, course: "Others" }
  ]
  },
  "Doctorate/Ph.D":{id:4,item:"Doctorate/Ph.D",detail:[
    { id: 0, course: "Sciences" },
    { id: 1, course: "Arts" },
    { id: 2, course: "Engineering" },
    { id: 3, course: "Management" },
    { id: 4, course: "Business" },
    { id: 5, course: "Humanities" },
    { id: 6, course: "Social Studies" },
    { id: 7, course: "Computer Science" },
    { id: 8, course: "Mechanical Engineering" },
    { id: 9, course: "Finance" },
    { id: 10, course: "Electrical and Electronics Engineering" },
    { id: 11, course: "Civil Engineering" },
    { id: 12, course: "Information Technology" },
    { id: 13, course: "Data Science and Analytics" },
    { id: 14, course: "Supply Chain Management" },
    { id: 15, course: "Hotel Management" },
    { id: 16, course: "Tourism and Hospitality" },
    { id: 17, course: "Business Analytics" },
    { id: 18, course: "Medicine" },
    { id: 19, course: "Education" },
    { id: 20, course: "Language" },
    { id: 21, course: "Journalism" },
    { id: 22, course: "Environmental Studies" },
    { id: 23, course: "Economics" },
    { id: 24, course: "Law" },
    { id: 25, course: "Political Science" },
    { id: 26, course: "Marketing" },
    { id: 27, course: "Nursing" },
    { id: 28, course: "Pharmacy" },
    { id: 29, course: "Other Courses" },
    { id: 30, course: "Natural Sciences" },
    { id: 31, course: "Biology" },
    { id: 32, course: "Music" },
    { id: 33, course: "History" },
    { id: 34, course: "Mathematics" },
    { id: 35, course: "Performing Arts" },
    { id: 36, course: "Psychology" },
    { id: 37, course: "Physics" },
    { id: 38, course: "Design" },
    { id: 39, course: "Chemistry" },
    { id: 40, course: "International Business" },
    { id: 41, course: "Accounting" },
    { id: 42, course: "Technology" },
    { id: 43, course: "Geography" },
    { id: 44, course: "Fine Arts" },
    { id: 45, course: "Biotechnology" },
    { id: 46, course: "Architecture" },
    { id: 47, course: "Philosophy" },
    { id: 48, course: "Media Studies" },
    { id: 49, course: "Sports Sciences" },
    { id: 50, course: "Biochemistry" },
    { id: 51, course: "Information Studies" },
    { id: 52, course: "Health Care" },
    { id: 53, course: "Agriculture" },
    { id: 54, course: "Film, Photography and Media" },
    { id: 55, course: "Literature" },
    { id: 56, course: "Graphic Design" },
    { id: 57, course: "English" },
    { id: 58, course: "Spanish" },
    { id: 59, course: "Anthropology" },
    { id: 60, course: "Communication Studies" },
    { id: 61, course: "Food Science" },
    { id: 62, course: "Biomedical Studies" },
    { id: 63, course: "Statistics" },
    { id: 64, course: "Writing" },
    { id: 65, course: "French" },
    { id: 66, course: "Entrepreneurship" },
    { id: 67, course: "Chemical Engineering" },
    { id: 68, course: "Human Resource Management" },
    { id: 69, course: "Fashion Design" },
    { id: 70, course: "Public Relations" },
    { id: 71, course: "Industrial Engineering" },
    { id: 72, course: "Kinesiology" },
    { id: 73, course: "Leadership" },
    { id: 74, course: "Neuroscience" },
    { id: 75, course: "Commerce" },
    { id: 76, course: "Forensic Sciences" },
    { id: 77, course: "Ecology" },
    { id: 78, course: "Software Engineering" },
    { id: 79, course: "Interior Design" },
    { id: 80, course: "Earth Sciences" },
    { id: 81, course: "Human Science" },
    { id: 82, course: "Public Policy" },
    { id: 83, course: "Animation" },
    { id: 84, course: "Electronic and Communications Engineering" },
    { id: 85, course: "Visual Arts" },
    { id: 86, course: "Games Development" },
    { id: 87, course: "Molecular Biology" },
    { id: 88, course: "Advertising" },
    { id: 89, course: "Astronomy" },
    { id: 90, course: "Materials Sciences" },
    { id: 91, course: "Microbiology" },
    { id: 92, course: "Construction Management" },
    { id: 93, course: "Actuarial Science" },
    { id: 94, course: "Marine Sciences" },
    { id: 95, course: "Aerospace Engineering" },
    { id: 96, course: "Archaeology" },
    { id: 97, course: "Dentistry" },
    { id: 98, course: "Real Estate" },
    { id: 99, course: "Zoology" },
    { id: 100, course: "Development Studies" },
    { id: 101, course: "Theology" },
    { id: 102, course: "Business Economics" },
    { id: 103, course: "Horticulture" },
    { id: 104, course: "Event Management" },
    { id: 105, course: "Engineering Management" },
    { id: 106, course: "Aviation" },
    { id: 107, course: "Physiotherapy" },
    { id: 108, course: "Medical Technology" },
    { id: 109, course: "Cyber Security" },
    { id: 110, course: "Sustainable Studies" },
    { id: 111, course: "Genetics" },
    { id: 112, course: "Plant Sciences" },
    { id: 113, course: "Veterinary" },
    { id: 114, course: "Automotive Engineering" },
    { id: 115, course: "Forestry" },
    { id: 116, course: "Risk Management" },
    { id: 117, course: "Energy and Power Engineering" },
    { id: 118, course: "Network Engineering" },
    { id: 119, course: "Acting" },
    { id: 120, course: "Agribusiness" },
    { id: 121, course: "European Studies" },
    { id: 122, course: "Project Management" },
    { id: 123, course: "Robotics" },
    { id: 124, course: "Gender Studies" },
    { id: 125, course: "Construction Engineering" },
    { id: 126, course: "Disaster Management" },
    { id: 127, course: "Artificial Intelligence and Machine Learning" },
    { id: 128, course: "Animal Sciences" },
    { id: 129, course: "Atmospheric Science" },
    { id: 130, course: "Medical Laboratory Sciences" },
    { id: 131, course: "Fisheries" },
    { id: 132, course: "Aeronautical Engineering" },
    { id: 133, course: "Mining Engineering" },
    { id: 134, course: "Banking" },
    { id: 135, course: "Bioinformatics" },
    { id: 136, course: "Soil Science" },
    { id: 137, course: "Agricultural Engineering" },
    { id: 138, course: "Clinical Science" },
    { id: 139, course: "Immunology" },
    { id: 140, course: "Culinary Arts" },
    { id: 141, course: "Counselling" },
    { id: 142, course: "Retail Management" },
    { id: 143, course: "Nanotechnology" },
    { id: 144, course: "Nuclear Engineering" },
    { id: 145, course: "Operations Management" },
    { id: 146, course: "Instrumentation and Control Engineering" },
    { id: 147, course: "Toxicology" },
    { id: 148, course: "Urban Studies" },
    { id: 149, course: "Digital Marketing" },
    { id: 150, course: "Agricultural Economics" },
    { id: 151, course: "MIS" },
    { id: 152, course: "Aboriginal Studies" },
    { id: 153, course: "Library Sciences" }
]

  },
  "Certificate":{id:5,item:"Certificate",detail:[
    { id: 0, course: "Sciences" },
    { id: 1, course: "Arts" },
    { id: 2, course: "Engineering" },
    { id: 3, course: "Management" },
    { id: 4, course: "Business" },
    { id: 5, course: "Humanities" },
    { id: 6, course: "Social Studies" },
    { id: 7, course: "Computer Science" },
    { id: 8, course: "Mechanical Engineering" },
    { id: 9, course: "Finance" },
    { id: 10, course: "Electrical and Electronics Engineering" },
    { id: 11, course: "Civil Engineering" },
    { id: 12, course: "Information Technology" },
    { id: 13, course: "Health Care" },
    { id: 14, course: "Digital Marketing" },
    { id: 15, course: "Event Management" },
    { id: 16, course: "Data Science and Analytics" },
    { id: 17, course: "Supply Chain Management" },
    { id: 18, course: "Hotel Management" },
    { id: 19, course: "Tourism and Hospitality" },
    { id: 20, course: "Business Analytics" },
    { id: 21, course: "Medicine" },
    { id: 22, course: "Education" },
    { id: 23, course: "Language" },
    { id: 24, course: "Journalism" },
    { id: 25, course: "Environmental Studies" },
    { id: 26, course: "Economics" },
    { id: 27, course: "Law" },
    { id: 28, course: "Political Science" },
    { id: 29, course: "Marketing" },
    { id: 30, course: "Nursing" },
    { id: 31, course: "Pharmacy" },
    { id: 32, course: "Other Courses" },
    { id: 33, course: "Natural Sciences" },
    { id: 34, course: "Biology" },
    { id: 35, course: "Music" },
    { id: 36, course: "History" },
    { id: 37, course: "Mathematics" },
    { id: 38, course: "Performing Arts" },
    { id: 39, course: "Psychology" },
    { id: 40, course: "Physics" },
    { id: 41, course: "Design" },
    { id: 42, course: "Chemistry" },
    { id: 43, course: "International Business" },
    { id: 44, course: "Accounting" },
    { id: 45, course: "Technology" },
    { id: 46, course: "Geography" },
    { id: 47, course: "Fine Arts" },
    { id: 48, course: "Biotechnology" },
    { id: 49, course: "Architecture" },
    { id: 50, course: "Philosophy" },
    { id: 51, course: "Media Studies" },
    { id: 52, course: "Sports Sciences" },
    { id: 53, course: "Biochemistry" },
    { id: 54, course: "Information Studies" },
    { id: 55, course: "Agriculture" },
    { id: 56, course: "Film, Photography and Media" },
    { id: 57, course: "Literature" },
    { id: 58, course: "Graphic Design" },
    { id: 59, course: "English" },
    { id: 60, course: "Spanish" },
    { id: 61, course: "Anthropology" },
    { id: 62, course: "Communication Studies" },
    { id: 63, course: "Food Science" },
    { id: 64, course: "Biomedical Studies" },
    { id: 65, course: "Statistics" },
    { id: 66, course: "Writing" },
    { id: 67, course: "French" },
    { id: 68, course: "Entrepreneurship" },
    { id: 69, course: "Chemical Engineering" },
    { id: 70, course: "Human Resource Management" },
    { id: 71, course: "Fashion Design" },
    { id: 72, course: "Public Relations" },
    { id: 73, course: "Industrial Engineering" },
    { id: 74, course: "Kinesiology" },
    { id: 75, course: "Leadership" },
    { id: 76, course: "Neuroscience" },
    { id: 77, course: "Commerce" },
    { id: 78, course: "Forensic Sciences" },
    { id: 79, course: "Ecology" },
    { id: 80, course: "Software Engineering" },
    { id: 81, course: "Interior Design" },
    { id: 82, course: "Earth Sciences" },
    { id: 83, course: "Human Science" },
    { id: 84, course: "Public Policy" },
    { id: 85, course: "Animation" },
    { id: 86, course: "Electronic and Communications Engineering" },
    { id: 87, course: "Visual Arts" },
    { id: 88, course: "Games Development" },
    { id: 89, course: "Molecular Biology" },
    { id: 90, course: "Advertising" },
    { id: 91, course: "Astronomy" },
    { id: 92, course: "Materials Sciences" },
    { id: 93, course: "Microbiology" },
    { id: 94, course: "Construction Management" },
    { id: 95, course: "Actuarial Science" },
    { id: 96, course: "Marine Sciences" },
    { id: 97, course: "Aerospace Engineering" },
    { id: 98, course: "Archaeology" },
    { id: 99, course: "Dentistry" },
    { id: 100, course: "Real Estate" },
    { id: 101, course: "Zoology" },
    { id: 102, course: "Development Studies" },
    { id: 103, course: "Theology" },
    { id: 104, course: "Business Economics" },
    { id: 105, course: "Horticulture" },
    { id: 106, course: "Engineering Management" },
    { id: 107, course: "Aviation" },
    { id: 108, course: "Physiotherapy" },
    { id: 109, course: "Medical Technology" },
    { id: 110, course: "Cyber Security" },
    { id: 111, course: "Sustainable Studies" },
    { id: 112, course: "Genetics" },
    { id: 113, course: "Plant Sciences" },
    { id: 114, course: "Veterinary" },
    { id: 115, course: "Automotive Engineering" },
    { id: 116, course: "Forestry" },
    { id: 117, course: "Risk Management" },
    { id: 118, course: "Energy and Power Engineering" },
    { id: 119, course: "Network Engineering" },
    { id: 120, course: "Acting" },
    { id: 121, course: "Agribusiness" },
    { id: 122, course: "European Studies" },
    { id: 123, course: "Project Management" },
    { id: 124, course: "Robotics" },
    { id: 125, course: "Gender Studies" },
    { id: 126, course: "Construction Engineering" },
    { id: 127, course: "Disaster Management" },
    { id: 128, course: "Artificial Intelligence and Machine Learning" },
    { id: 129, course: "Animal Sciences" },
    { id: 130, course: "Atmospheric Science" },
    { id: 131, course: "Medical Laboratory Sciences" },
    { id: 132, course: "Fisheries" },
    { id: 133, course: "Aeronautical Engineering" },
    { id: 134, course: "Mining Engineering" },
    { id: 135, course: "Banking" },
    { id: 136, course: "Bioinformatics" },
    { id: 137, course: "Soil Science" },
    { id: 138, course: "Agricultural Engineering" },
    { id: 139, course: "Clinical Science" },
    { id: 140, course: "Immunology" },
    { id: 141, course: "Culinary Arts" },
    { id: 142, course: "Counselling" },
    { id: 143, course: "Retail Management" },
    { id: 144, course: "Nanotechnology" },
    { id: 145, course: "Nuclear Engineering" },
    { id: 146, course: "Operations Management" },
    { id: 147, course: "Instrumentation and Control Engineering" },
    { id: 148, course: "Toxicology" },
    { id: 149, course: "Urban Studies" },
    { id: 150, course: "Agricultural Economics" },
    { id: 151, course: "MIS" },
    { id: 152, course: "Aboriginal Studies" },
    { id: 153, course: "Library Sciences" },
    { id: 154, course: "Instructional Design" },
    { id: 155, course: "Chinese" }
]

  },
  "Grad Certificate":{id:6,item:"Grad Certificate",detail:[
    { id: 0, course: "Sciences" },
    { id: 1, course: "Arts" },
    { id: 2, course: "Engineering" },
    { id: 3, course: "Management" },
    { id: 4, course: "Business" },
    { id: 5, course: "Humanities" },
    { id: 6, course: "Social Studies" },
    { id: 7, course: "Computer Science" },
    { id: 8, course: "Mechanical Engineering" },
    { id: 9, course: "Finance" },
    { id: 10, course: "Electrical and Electronics Engineering" },
    { id: 11, course: "Civil Engineering" },
    { id: 12, course: "Information Technology" },
    { id: 13, course: "Health Care" },
    { id: 14, course: "Digital Marketing" },
    { id: 15, course: "Event Management" },
    { id: 16, course: "Data Science and Analytics" },
    { id: 17, course: "Supply Chain Management" },
    { id: 18, course: "Hotel Management" },
    { id: 19, course: "Tourism and Hospitality" },
    { id: 20, course: "Business Analytics" },
    { id: 21, course: "Medicine" },
    { id: 22, course: "Education" },
    { id: 23, course: "Language" },
    { id: 24, course: "Journalism" },
    { id: 25, course: "Environmental Studies" },
    { id: 26, course: "Economics" },
    { id: 27, course: "Law" },
    { id: 28, course: "Political Science" },
    { id: 29, course: "Marketing" },
    { id: 30, course: "Nursing" },
    { id: 31, course: "Pharmacy" },
    { id: 32, course: "Other Courses" },
    { id: 33, course: "Natural Sciences" },
    { id: 34, course: "Biology" },
    { id: 35, course: "Music" },
    { id: 36, course: "History" },
    { id: 37, course: "Mathematics" },
    { id: 38, course: "Performing Arts" },
    { id: 39, course: "Psychology" },
    { id: 40, course: "Physics" },
    { id: 41, course: "Design" },
    { id: 42, course: "Chemistry" },
    { id: 43, course: "International Business" },
    { id: 44, course: "Accounting" },
    { id: 45, course: "Technology" },
    { id: 46, course: "Geography" },
    { id: 47, course: "Fine Arts" },
    { id: 48, course: "Biotechnology" },
    { id: 49, course: "Architecture" },
    { id: 50, course: "Philosophy" },
    { id: 51, course: "Media Studies" },
    { id: 52, course: "Sports Sciences" },
    { id: 53, course: "Biochemistry" },
    { id: 54, course: "Information Studies" },
    { id: 55, course: "Agriculture" },
    { id: 56, course: "Film, Photography and Media" },
    { id: 57, course: "Literature" },
    { id: 58, course: "Graphic Design" },
    { id: 59, course: "English" },
    { id: 60, course: "Spanish" },
    { id: 61, course: "Anthropology" },
    { id: 62, course: "Communication Studies" },
    { id: 63, course: "Food Science" },
    { id: 64, course: "Biomedical Studies" },
    { id: 65, course: "Statistics" },
    { id: 66, course: "Writing" },
    { id: 67, course: "French" },
    { id: 68, course: "Entrepreneurship" },
    { id: 69, course: "Chemical Engineering" },
    { id: 70, course: "Human Resource Management" },
    { id: 71, course: "Fashion Design" },
    { id: 72, course: "Public Relations" },
    { id: 73, course: "Industrial Engineering" },
    { id: 74, course: "Kinesiology" },
    { id: 75, course: "Leadership" },
    { id: 76, course: "Neuroscience" },
    { id: 77, course: "Commerce" },
    { id: 78, course: "Forensic Sciences" },
    { id: 79, course: "Ecology" },
    { id: 80, course: "Software Engineering" },
    { id: 81, course: "Interior Design" },
    { id: 82, course: "Earth Sciences" },
    { id: 83, course: "Human Science" },
    { id: 84, course: "Public Policy" },
    { id: 85, course: "Animation" },
    { id: 86, course: "Electronic and Communications Engineering" },
    { id: 87, course: "Visual Arts" },
    { id: 88, course: "Games Development" },
    { id: 89, course: "Molecular Biology" },
    { id: 90, course: "Advertising" },
    { id: 91, course: "Astronomy" },
    { id: 92, course: "Materials Sciences" },
    { id: 93, course: "Microbiology" },
    { id: 94, course: "Construction Management" },
    { id: 95, course: "Actuarial Science" },
    { id: 96, course: "Marine Sciences" },
    { id: 97, course: "Aerospace Engineering" },
    { id: 98, course: "Archaeology" },
    { id: 99, course: "Dentistry" },
    { id: 100, course: "Real Estate" },
    { id: 101, course: "Zoology" },
    { id: 102, course: "Development Studies" },
    { id: 103, course: "Theology" },
    { id: 104, course: "Business Economics" },
    { id: 105, course: "Horticulture" },
    { id: 106, course: "Engineering Management" },
    { id: 107, course: "Aviation" },
    { id: 108, course: "Physiotherapy" },
    { id: 109, course: "Medical Technology" },
    { id: 110, course: "Cyber Security" },
    { id: 111, course: "Sustainable Studies" },
    { id: 112, course: "Genetics" },
    { id: 113, course: "Plant Sciences" },
    { id: 114, course: "Veterinary" },
    { id: 115, course: "Automotive Engineering" },
    { id: 116, course: "Forestry" },
    { id: 117, course: "Risk Management" },
    { id: 118, course: "Energy and Power Engineering" },
    { id: 119, course: "Network Engineering" },
    { id: 120, course: "Acting" },
    { id: 121, course: "Agribusiness" },
    { id: 122, course: "European Studies" },
    { id: 123, course: "Project Management" },
    { id: 124, course: "Robotics" },
    { id: 125, course: "Gender Studies" },
    { id: 126, course: "Construction Engineering" },
    { id: 127, course: "Disaster Management" },
    { id: 128, course: "Artificial Intelligence and Machine Learning" },
    { id: 129, course: "Animal Sciences" },
    { id: 130, course: "Atmospheric Science" },
    { id: 131, course: "Medical Laboratory Sciences" },
    { id: 132, course: "Fisheries" },
    { id: 133, course: "Aeronautical Engineering" },
    { id: 134, course: "Mining Engineering" },
    { id: 135, course: "Banking" },
    { id: 136, course: "Bioinformatics" },
    { id: 137, course: "Soil Science" },
    { id: 138, course: "Agricultural Engineering" },
    { id: 139, course: "Clinical Science" },
    { id: 140, course: "Immunology" },
    { id: 141, course: "Culinary Arts" },
    { id: 142, course: "Counselling" },
    { id: 143, course: "Retail Management" },
    { id: 144, course: "Nanotechnology" },
    { id: 145, course: "Nuclear Engineering" },
    { id: 146, course: "Operations Management" },
    { id: 147, course: "Instrumentation and Control Engineering" },
    { id: 148, course: "Toxicology" },
    { id: 149, course: "Urban Studies" },
    { id: 150, course: "Agricultural Economics" },
    { id: 151, course: "MIS" },
    { id: 152, course: "Aboriginal Studies" },
    { id: 153, course: "Library Sciences" },
    { id: 154, course: "Instructional Design" }
]

  },
  "Diploma":{id:7,item:"Diploma",detail:[
    { id: 0, course: "Sciences" },
    { id: 1, course: "Arts" },
    { id: 2, course: "Engineering" },
    { id: 3, course: "Management" },
    { id: 4, course: "Business" },
    { id: 5, course: "Humanities" },
    { id: 6, course: "Social Studies" },
    { id: 7, course: "Computer Science" },
    { id: 8, course: "Mechanical Engineering" },
    { id: 9, course: "Finance" },
    { id: 10, course: "Electrical and Electronics Engineering" },
    { id: 11, course: "Civil Engineering" },
    { id: 12, course: "Information Technology" },
    { id: 13, course: "Health Care" },
    { id: 14, course: "Digital Marketing" },
    { id: 15, course: "Event Management" },
    { id: 16, course: "Data Science and Analytics" },
    { id: 17, course: "Supply Chain Management" },
    { id: 18, course: "Hotel Management" },
    { id: 19, course: "Tourism and Hospitality" },
    { id: 20, course: "Business Analytics" },
    { id: 21, course: "Medicine" },
    { id: 22, course: "Education" },
    { id: 23, course: "Language" },
    { id: 24, course: "Journalism" },
    { id: 25, course: "Environmental Studies" },
    { id: 26, course: "Economics" },
    { id: 27, course: "Law" },
    { id: 28, course: "Political Science" },
    { id: 29, course: "Marketing" },
    { id: 30, course: "Nursing" },
    { id: 31, course: "Pharmacy" },
    { id: 32, course: "Other Courses" },
    { id: 33, course: "Natural Sciences" },
    { id: 34, course: "Biology" },
    { id: 35, course: "Music" },
    { id: 36, course: "History" },
    { id: 37, course: "Mathematics" },
    { id: 38, course: "Performing Arts" },
    { id: 39, course: "Psychology" },
    { id: 40, course: "Physics" },
    { id: 41, course: "Design" },
    { id: 42, course: "Chemistry" },
    { id: 43, course: "International Business" },
    { id: 44, course: "Accounting" },
    { id: 45, course: "Technology" },
    { id: 46, course: "Geography" },
    { id: 47, course: "Fine Arts" },
    { id: 48, course: "Biotechnology" },
    { id: 49, course: "Architecture" },
    { id: 50, course: "Philosophy" },
    { id: 51, course: "Media Studies" },
    { id: 52, course: "Sports Sciences" },
    { id: 53, course: "Biochemistry" },
    { id: 54, course: "Information Studies" },
    { id: 55, course: "Agriculture" },
    { id: 56, course: "Film, Photography and Media" },
    { id: 57, course: "Literature" },
    { id: 58, course: "Graphic Design" },
    { id: 59, course: "English" },
    { id: 60, course: "Spanish" },
    { id: 61, course: "Anthropology" },
    { id: 62, course: "Communication Studies" },
    { id: 63, course: "Food Science" },
    { id: 64, course: "Biomedical Studies" },
    { id: 65, course: "Statistics" },
    { id: 66, course: "Writing" },
    { id: 67, course: "French" },
    { id: 68, course: "Entrepreneurship" },
    { id: 69, course: "Chemical Engineering" },
    { id: 70, course: "Human Resource Management" },
    { id: 71, course: "Fashion Design" },
    { id: 72, course: "Public Relations" },
    { id: 73, course: "Industrial Engineering" },
    { id: 74, course: "Kinesiology" },
    { id: 75, course: "Leadership" },
    { id: 76, course: "Neuroscience" },
    { id: 77, course: "Commerce" },
    { id: 78, course: "Forensic Sciences" },
    { id: 79, course: "Ecology" },
    { id: 80, course: "Software Engineering" },
    { id: 81, course: "Interior Design" },
    { id: 82, course: "Earth Sciences" },
    { id: 83, course: "Human Science" },
    { id: 84, course: "Public Policy" },
    { id: 85, course: "Animation" },
    { id: 86, course: "Electronic and Communications Engineering" },
    { id: 87, course: "Visual Arts" },
    { id: 88, course: "Games Development" },
    { id: 89, course: "Molecular Biology" },
    { id: 90, course: "Advertising" },
    { id: 91, course: "Astronomy" },
    { id: 92, course: "Materials Sciences" },
    { id: 93, course: "Microbiology" },
    { id: 94, course: "Construction Management" },
    { id: 95, course: "Actuarial Science" },
    { id: 96, course: "Marine Sciences" },
    { id: 97, course: "Aerospace Engineering" },
    { id: 98, course: "Archaeology" },
    { id: 99, course: "Dentistry" },
    { id: 100, course: "Real Estate" },
    { id: 101, course: "Zoology" },
    { id: 102, course: "Development Studies" },
    { id: 103, course: "Theology" },
    { id: 104, course: "Business Economics" },
    { id: 105, course: "Horticulture" },
    { id: 106, course: "Engineering Management" },
    { id: 107, course: "Aviation" },
    { id: 108, course: "Physiotherapy" },
    { id: 109, course: "Medical Technology" },
    { id: 110, course: "Cyber Security" },
    { id: 111, course: "Sustainable Studies" },
    { id: 112, course: "Genetics" },
    { id: 113, course: "Plant Sciences" },
    { id: 114, course: "Veterinary" },
    { id: 115, course: "Automotive Engineering" },
    { id: 116, course: "Forestry" },
    { id: 117, course: "Risk Management" },
    { id: 118, course: "Energy and Power Engineering" },
    { id: 119, course: "Network Engineering" },
    { id: 120, course: "Acting" },
    { id: 121, course: "Agribusiness" },
    { id: 122, course: "European Studies" },
    { id: 123, course: "Project Management" },
    { id: 124, course: "Robotics" },
    { id: 125, course: "Gender Studies" },
    { id: 126, course: "Construction Engineering" },
    { id: 127, course: "Disaster Management" },
    { id: 128, course: "Artificial Intelligence and Machine Learning" },
    { id: 129, course: "Animal Sciences" },
    { id: 130, course: "Atmospheric Science" },
    { id: 131, course: "Medical Laboratory Sciences" },
    { id: 132, course: "Fisheries" },
    { id: 133, course: "Aeronautical Engineering" },
    { id: 134, course: "Mining Engineering" },
    { id: 135, course: "Banking" },
    { id: 136, course: "Bioinformatics" },
    { id: 137, course: "Soil Science" },
    { id: 138, course: "Agricultural Engineering" },
    { id: 139, course: "Clinical Science" },
    { id: 140, course: "Immunology" },
    { id: 141, course: "Culinary Arts" },
    { id: 142, course: "Counselling" },
    { id: 143, course: "Retail Management" },
    { id: 144, course: "Nanotechnology" },
    { id: 145, course: "Nuclear Engineering" },
    { id: 146, course: "Operations Management" },
    { id: 147, course: "Instrumentation and Control Engineering" },
    { id: 148, course: "Toxicology" },
    { id: 149, course: "Urban Studies" },
    { id: 150, course: "Agricultural Economics" },
    { id: 151, course: "MIS" },
    { id: 152, course: "Aboriginal Studies" },
    { id: 153, course: "Library Sciences" },
    { id: 154, course: "Instructional Design" }
  ]
  },
  "PG Diploma":{id:8,item:"PG Diploma",detail:[{id: 0, course: "Sciences"},
  {id: 1, course: "Arts"},
  {id: 2, course: "Engineering"},
  {id: 3, course: "Management"},
  {id: 4, course: "Business"},
  {id: 5, course: "Humanities"},
  {id: 6, course: "Social Studies"},
  {id: 7, course: "Computer Science"},
  {id: 8, course: "Mechanical Engineering"},
  {id: 9, course: "Finance"},
  {id: 10, course: "Electrical and Electronics Engineering"},
  {id: 11, course: "Civil Engineering"},
  {id: 12, course: "Information Technology"},
  {id: 13, course: "Health Care"},
  {id: 14, course: "Digital Marketing"},
  {id: 15, course: "Event Management"},
  {id: 16, course: "Data Science and Analytics"},
  {id: 17, course: "Supply Chain Management"},
  {id: 18, course: "Hotel Management"},
  {id: 19, course: "Tourism and Hospitality"},
  {id: 20, course: "Business Analytics"},
  {id: 21, course: "Medicine"},
  {id: 22, course: "Education"},
  {id: 23, course: "Language"},
  {id: 24, course: "Journalism"},
  {id: 25, course: "Environmental Studies"},
  {id: 26, course: "Economics"},
  {id: 27, course: "Law"},
  {id: 28, course: "Political Science"},
  {id: 29, course: "Marketing"},
  {id: 30, course: "Nursing"},
  {id: 31, course: "Pharmacy"},
  {id: 32, course: "Other Courses"},
  {id: 33, course: "Natural Sciences"},
  {id: 34, course: "Biology"},
  {id: 35, course: "Music"},
  {id: 36, course: "History"},
  {id: 37, course: "Mathematics"},
  {id: 38, course: "Performing Arts"},
  {id: 39, course: "Psychology"},
  {id: 40, course: "Physics"},
  {id: 41, course: "Design"},
  {id: 42, course: "Chemistry"},
  {id: 43, course: "International Business"},
  {id: 44, course: "Accounting"},
  {id: 45, course: "Technology"},
  {id: 46, course: "Geography"},
  {id: 47, course: "Fine Arts"},
  {id: 48, course: "Biotechnology"},
  {id: 49, course: "Architecture"},
  {id: 50, course: "Philosophy"},
  {id: 51, course: "Media Studies"},
  {id: 52, course: "Sports Sciences"},
  {id: 53, course: "Biochemistry"},
  {id: 54, course: "Information Studies"},
  {id: 55, course: "Agriculture"},
  {id: 56, course: "Film, Photography and Media"},
  {id: 57, course: "Literature"},
  {id: 58, course: "Graphic Design"},
  {id: 59, course: "English"},
  {id: 60, course: "Spanish"},
  {id: 61, course: "Anthropology"},
  {id: 62, course: "Communication Studies"},
  {id: 63, course: "Food Science"},
  {id: 64, course: "Biomedical Studies"},
  {id: 65, course: "Statistics"},
  {id: 66, course: "Writing"},
  {id: 67, course: "French"},
  {id: 68, course: "Entrepreneurship"},
  {id: 69, course: "Chemical Engineering"},
  {id: 70, course: "Human Resource Management"},
  {id: 71, course: "Fashion Design"},
  {id: 72, course: "Public Relations"},
  {id: 73, course: "Industrial Engineering"},
  {id: 74, course: "Kinesiology"},
  {id: 75, course: "Leadership"},
  {id: 76, course: "Neuroscience"},
  {id: 77, course: "Commerce"},
  {id: 78, course: "Forensic Sciences"},
  {id: 79, course: "Ecology"},
  {id: 80, course: "Software Engineering"},
  {id: 81, course: "Interior Design"},
  {id: 82, course: "Earth Sciences"},
  {id: 83, course: "Human Science"},
  {id: 84, course: "Public Policy"},
  {id: 85, course: "Animation"},
  {id: 86, course: "Electronic and Communications Engineering"},
  {id: 87, course: "Visual Arts"},
  {id: 88, course: "Games Development"},
  {id: 89, course: "Molecular Biology"},
  {id: 90, course: "Advertising"},
  {id: 91, course: "Astronomy"},
  {id: 92, course: "Materials Sciences"},
  {id: 93, course: "Microbiology"},
  {id: 94, course: "Construction Management"},
  {id: 95, course: "Actuarial Science"},
  {id: 96, course: "Marine Sciences"},
  {id: 97, course: "Aerospace Engineering"},
  {id: 98, course: "Archaeology"},
  {id: 99, course: "Dentistry"},
  {id: 100, course: "Real Estate"},
  {id: 101, course: "Zoology"},
  {id: 102, course: "Development Studies"},
  {id: 103, course: "Theology"},
  {id: 104, course: "Business Economics"},
  {id: 105, course: "Horticulture"},
  {id: 106, course: "Engineering Management"},
  {id: 107, course: "Aviation"},
  {id: 108, course: "Physiotherapy"},
  {id: 109, course: "Medical Technology"},
  {id: 110, course: "Cyber Security"},
  {id: 111, course: "Sustainable Studies"},
  {id: 112, course: "Genetics"},
  {id: 113, course: "Plant Sciences"},
  {id: 114, course: "Veterinary"},
  {id: 115, course: "Automotive Engineering"},
  {id: 116, course: "Forestry"},
  {id: 117, course: "Risk Management"},
  {id: 118, course: "Energy and Power Engineering"},
  {id: 119, course: "Network Engineering"},
  {id: 120, course: "Acting"},
  {id: 121, course: "Agribusiness"},
  {id: 122, course: "European Studies"},
  {id: 123, course: "Project Management"},
  {id: 124, course: "Robotics"},
  {id: 125, course: "Gender Studies"},
  {id: 126, course: "Construction Engineering"},
  {id: 127, course: "Disaster Management"},
  {id: 128, course: "Artificial Intelligence and Machine Learning"},
  {id: 129, course: "Animal Sciences"},
  {id: 130, course: "Atmospheric Science"},
  {id: 131, course: "Medical Laboratory Sciences"},
  {id: 132, course: "Fisheries"},
  {id: 133, course: "Aeronautical Engineering"},
  {id: 134, course: "Mining Engineering"},
  {id: 135, course: "Banking"},
  {id: 136, course: "Bioinformatics"},
  {id: 137, course: "Soil Science"},
  {id: 138, course: "Agricultural Engineering"},
  {id: 139, course: "Clinical Science"},
  {id: 140, course: "Immunology"},
  {id: 141, course: "Culinary Arts"},
  {id: 142, course: "Counselling"},
  {id: 143, course: "Retail Management"},
  {id: 144, course: "Nanotechnology"},
  {id: 145, course: "Nuclear Engineering"},
  {id: 146, course: "Operations Management"},
  {id: 147, course: "Instrumentation and Control Engineering"},
  {id: 148, course: "Toxicology"},
  {id: 149, course: "Urban Studies"},
  {id: 150, course: "Agricultural Economics"},
  {id: 151, course: "MIS"},
  {id: 152, course: "Aboriginal Studies"},
  {id: 153, course: "Library Sciences"}]
 }
}

const studyAboradUrl= process.env.REACT_APP_API_BASE_URL

const StudyAbroadPopUp=()=>{
        const [email, setEmail] = useState('');
        const [name, setName] = useState('');
        const [city, setCity] = useState('');
        const [course, setCourse] = useState('Sciences');
        const [phoneNumber, setPhoneNumber] = useState("");
        const [comment, setComment] = useState('');
        const [successMsg, setSuccessMsg] = useState('');
        const [errorMsg, setErrorMsg] = useState('');
        const [category,setCategory] = useState("Bachelors")
        const [showLoader, setShowLoader] = useState(false)
        
  
        const navigate = useNavigate();



  
        const closePopupPage = () => {
          navigate("/studyabroad");
        };
        
        const handleSubmit = async (event) => {
          event.preventDefault();
          setShowLoader(true)
          try {
            const response = await fetch(
              `${studyAboradUrl}/studyabroad`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  name,
                  email,
                  course,
                  category,
                  phoneNumber,
                  city,
                  comment
                })
              }
            );
            if (response.ok) {
              const data = await response.json();
              setSuccessMsg(data.success);
              setErrorMsg('');
              setName("");
              setEmail("");
              setCourse("Sciences");
              setCategory("Bachelors");
              setPhoneNumber("");
              setCity("");
              setComment("");
              setShowLoader(false);
            } else {
              const data = await response.json();
              setErrorMsg(data.error);
              setSuccessMsg('');
              setName("");
              setEmail("");
              setCourse("Sciences");
              setCategory("Bachelors");
              setPhoneNumber("");
              setCity("");
              setComment("");
              setShowLoader(false)
            }
          } catch (error) {
            console.error(error);
          }
        };

    return(
      <div className="study-abroad-popup-main-container">
        <div className="study-abroad-popup-container">
            <span className="study-abroad-popup-close-button" onClick={closePopupPage}>
               &times;
             </span>
             <div>
            <div className="study-abroad-popup-container1">
                <div className="study-abroad-popup-icon-container">
                    <AiOutlineForm className="study-abroad-popup-icon" />
                </div>
                <div className="study-abroad-popup-icon-right-container">
                    <h3 className="study-abroad-popup-icon-right-container-heading">Register Now To Consult A Counsellor</h3>
                    <p className="study-abroad-popup-icon-right-container-text">Get details and latest updates</p>
                </div>
            </div>
            <hr className="study-abroad-line1" />
            </div>
            <div className="study-abroad-popup-inputs-container">
                <div className="study-abroad-popup-inputs-icon-container">
                  <BiSolidUser className="study-abroad-popup-input-icon"/>
                <input
                    type="text"
                    id="name"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="study-ppr-popup-input"
                    required
                />
                </div>
                <div className="study-abroad-popup-inputs-icon-container">
                  <MdEmail className="study-abroad-popup-input-icon"/>
                <input
                    type="email"
                    id="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="study-ppr-popup-input"
                    required
                />
                </div>
                <div className="study-abroad-popup-inputs-icon-container">
                  <BiSolidPhoneCall className="study-abroad-popup-input-icon"/>
                <input
                    type="number"
                    id="phonenumber"
                    placeholder="Mobile Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="study-ppr-popup-input"
                    required
                />
                </div>
                <div className="study-abroad-popup-inputs-icon-container">
                <BiSolidCategory className="study-abroad-popup-input-icon"/>
                <select
               value={category}
               onChange={(e) => setCategory(e.target.value)}
               className="study-ppr-popup-input study-ppr-popup-select"
               required>
               {SelectCourseList.map(each=>(
                <option key={each.item} className="study-ppr-popup-select-option" >{each.item}</option>
              ))}
               </select>
             </div>
             <div className="study-abroad-popup-inputs-icon-container">
             <PiCertificateFill className="study-abroad-popup-input-icon"/>
             <select
               id="course"
               value={course}
               onChange={(e) => setCourse(e.target.value)}
               className="study-ppr-popup-input study-ppr-popup-select"
               required>
                {SelectCourseList1[category].detail.map((each)=>(
                <option key={each.course} className="study-ppr-popup-select-option">{each.course}</option>
              ))}
             </select>
             </div>
                <div className="study-abroad-popup-inputs-icon-container">
                  <MdLocationPin className="study-abroad-popup-input-icon"/>
                <input
                    type="text"
                    id="city"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="study-ppr-popup-input"
                    required/>
                  </div>
                <div className="study-abroad-popup-inputs-icon-container">
                  <FaCommentDots className="study-abroad-popup-input-icon"/>
                <input
                    type="text"
                    id="comment"
                    placeholder="Enter your Comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="study-ppr-popup-input"/>
                  </div>
            </div>
            <p className="studyabordpopup-response">{successMsg}{errorMsg}</p>
            <Button text="Submit"
                    onSubmit={handleSubmit}
                    loading={showLoader}
                    disabled={showLoader}/>
        </div>
        </div>
    )
}

export default StudyAbroadPopUp


